@font-face {
  font-family: "CormorantGaramond";
  src: url("/fonts/CormorantGaramond-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #fffff8;
  font-family: "CormorantGaramond", serif;
  font-size: 26px;
  line-height: 1.5em;
}

h1 {
  font-size: 48px;
  font-weight: normal;
  letter-spacing: -1px;
}

p {
  margin: 0 0 1.6rem;
}

img {
  border: 1px solid #eee;
  border-radius: 3px;
}

a {
  text-decoration: none;
}

a:active,
a:active:hover,
a:active:focus,
a:focus,
a:hover {
  text-decoration: dotted;
}

@media (max-width: 767px) {

  main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  h1 {
    order: -1;
    margin: 2rem 0;
  }

  img {
    margin-bottom: 1.6rem;
    object-fit: contain;
  }
}

@media (min-width: 768px) {

  main {
    max-width: 1000px;
    margin: 150px auto 0;
  }

  h1 {
    margin: 0 0 3rem;
  }

  img {
    float: right;
    margin: 0 0 20px 20px;
  }
}
